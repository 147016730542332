@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');

body {
    font-family: 'Open Sans', sans-serif;
    background: #EDEDED;
    background-image: url(../src/assets/church.png);
}

:root {
    --transition: all 400ms ease;
}